.app {
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    min-height: 100vh;
    background-color: #ffc0cb;
    color: #001339;
    display: flex;
    flex-direction: column;
    justify-content: space-around;  
  }

  .privacy-policy {
    text-align: center;
  }

.privacy-policy-link, .privacy-policy-link:visited {
    text-decoration: none;
    color: #333;
}