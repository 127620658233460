h1, h2, h3, h4, h5, h6, p, body {
  margin: 0;
  font-family: 'Lovelo', 'Roboto', 'Open Sans',
    sans-serif;
  color: #001339;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 
- Fifth: Nickainley
- Dinky Beats: Lovelo
 */